import mainLayoutRoutes from 'routes/routes/layout';
import logoLayoutRoutes from 'routes/routes/logo';
import redirections from 'routes/routes/redirections';

// privateRoutes contain all the private routes, needed for the logout logic to determine
// where the user should be redirected
export const privateRoutes = ['/my-profile', '/verify-email/', '/users/'];

// export all the routes and redirections
export default {
  routes: {
    mainLayout: mainLayoutRoutes,
    logoLayout: logoLayoutRoutes,
  },
  redirections,
};
