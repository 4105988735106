import { useContext } from 'react';
import ToastContext from 'context/ToastContext';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

/**
 __Toast__ is an absolutely positioned component alerting the users about
 the status of their operations.

 __Context component__: `Toast` does not have its own props, all its contents
 come from the `ToastContext` properties. The properties that can be modified
 are `content` (the text inside the Toast) and `type` (indicating what kind of
 information is being displayed).
 _In the future, if needed, the modifiable properties could be expanded._
 */
function Toast() {
  const { toast, setToast } = useContext(ToastContext);

  if (!toast?.content) {
    return null;
  }

  const handleClose = () =>
    setToast((prevState) => ({
      ...prevState,
      open: false,
    }));

  let snackbarInnerContent;
  let snackbarMessage;
  if (['error', 'success', 'warning'].includes(toast.type)) {
    snackbarInnerContent = (
      <Alert severity={toast.type} variant="filled">
        {toast.content}
      </Alert>
    );
    snackbarMessage = null;
  } else {
    snackbarInnerContent = null;
    snackbarMessage = toast.content;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: toast.vertical,
        horizontal: toast.horizontal,
      }}
      open={toast.open}
      onClose={handleClose}
      message={snackbarMessage}
      autoHideDuration={toast.autoHideDuration}
    >
      {snackbarInnerContent}
    </Snackbar>
  );
}

export default Toast;
