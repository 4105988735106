import PropTypes from 'prop-types';

import NavLink from 'components/NavLink/NavLink';

import './HeaderNavLink.css';

/**
 __HeaderNavLink__ is a header's version of Link.
 */
function HeaderNavLink({ children, to, className, onClickHandler, ...props }) {
  const classes = `${className} header-nav-link`;
  const onClick = () => {
    document.activeElement.blur();

    onClickHandler();
  };

  return (
    <NavLink className={classes} to={to} onClick={() => onClick()} {...props}>
      {children}
    </NavLink>
  );
}

HeaderNavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
};

HeaderNavLink.defaultProps = {
  children: null,
  className: '',
};

export default HeaderNavLink;
