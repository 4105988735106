import { lazy } from 'react';

const Profile = lazy(() => import('views/internal/Profile/Profile'));

// private routes of the main layout (header+footer)
export default [
  {
    // a path of the desired view
    path: '/my-profile',
    // indicates whether the route should be exact or just the beginning should match
    exact: true,
    // indicates whether a logged in but unverified user should have access
    allowUnverified: true,
    // the component to be rendered at the given path
    Element: Profile,
  },
];
