import { lazy } from 'react';

const EmailVerification = lazy(
  () => import('views/auth/EmailVerification/EmailVerification'),
);

// private routes of the logo layout
export default [
  {
    // a path of the desired view - together with the named parameter to be passed
    // as a route specification
    path: '/verify-email/:token',
    // indicates whether a logged in but unverified user should have access
    allowUnverified: true,
    // the component to be rendered at the given path
    Element: EmailVerification,
  },
];
