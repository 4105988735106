import PropTypes from 'prop-types';

import { Link as RRDLink } from 'react-router-dom';

import './Link.css';

/**
 __Link__ is a footer's version of Link.
*/
function Link({ children, to, className, ...props }) {
  const classes = `${className} link`;
  return (
    <RRDLink className={classes} to={to} {...props}>
      {children}
    </RRDLink>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Link.defaultProps = {
  children: null,
  className: '',
};

export default Link;
