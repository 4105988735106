import commonRoutes from 'routes/routes/layout/common';
import privateRoutes from 'routes/routes/layout/private';
import MainLayout from 'layouts/MainLayout/MainLayout';

// export all the routes of the main layout (header+footer)
export default {
  root: {
    path: '/',
    Element: MainLayout,
  },
  routes: {
    common: commonRoutes,
    private: privateRoutes,
    public: [],
  },
};
