import axios from 'axios';
import { envOrDefault } from 'utils/misc';

// Back-end url
const BASE_URL = envOrDefault(
  'REACT_APP_BASE_API_URL',
  'http://localhost:8000',
);

// Defaults required for Laravel auth
axios.defaults.withCredentials = true;

const apiClient = axios.create({
  baseURL: `${BASE_URL}/api/`,
});

const authClient = axios.create({
  baseURL: `${BASE_URL}/auth/`,
});

export { BASE_URL, apiClient, authClient };
