import axios from 'axios';
import { apiClient, authClient, BASE_URL } from 'api/config/defaults';

export const request = async (client, options) => {
  const onSuccess = (response) => response.data;

  const onError = async (error) => {
    // Refreshing CSRF token after expiration
    if (error?.response?.status === 419) {
      // Refresh our session token
      await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);

      // Return a new request using the original request's configuration
      return axios(error.response.config);
    }

    // Give a proper error message for 429 errors
    if (error?.response?.status === 429) {
      const modifiedErrorResponse = error.response;
      modifiedErrorResponse.data.errors = {
        email: ['Too many failed attempts. Try again later.'],
      };
      return Promise.reject(modifiedErrorResponse);
    }

    // Give a proper error message for 413 errors
    if (error?.response?.status === 413) {
      const modifiedErrorResponse = error.response;
      modifiedErrorResponse.data = {
        message: 'The file you are trying to send is too large.',
      };
      return Promise.reject(modifiedErrorResponse);
    }

    // If an error has no response, reject the promise with the entire error
    // to be able to extract information
    return Promise.reject(error?.response || error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export const apiRequest = async (options) => request(apiClient, options);

export const authRequest = async (options) => request(authClient, options);
