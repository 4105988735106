import { memo, useContext } from 'react';
import IconButton from '@mui/material/IconButton';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

import ModalContext from 'context/ModalContext';
import FooterNavLink from 'layouts/MainLayout/components/Footer/components/FooterNavLink/FooterNavLink';
import Link from 'layouts/MainLayout/components/Footer/components/Link/Link';
import menu from 'layouts/MainLayout/utils/menu';

import './Footer.css';

/**
 __Footer__ is the component displayed on the bottom of the page of the default layout.
 It displays the navigation links to the main pages of the website and others,
 like official documents or terms and conditions.
*/
function Footer() {
  const { modalContent } = useContext(ModalContext);

  let classes = 'footer';
  // Blur the footer if a modal is active
  classes += modalContent ? ' blurred' : '';

  return (
    <footer className={classes}>
      <div className="line" />
      <div className="footer-box">
        <nav className="footer-nav">
          <ul className="nav-list">
            {menu.map(({ name, link }) => (
              <li key={`header-link-${name}`}>
                <FooterNavLink to={link}>{name}</FooterNavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="footer-social">
          <IconButton
            target="_blank"
            href="https://www.linkedin.com/company/eestec/"
            rel="noreferrer"
            aria-label="linkedin"
          >
            <LinkedInIcon className="footer-icons" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.instagram.com/eestec/"
            rel="noreferrer"
            aria-label="instagram"
          >
            <InstagramIcon className="footer-icons" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.facebook.com/EESTEC/"
            rel="noreferrer"
            aria-label="facebook"
          >
            <FacebookIcon className="footer-icons" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.youtube.com/channel/UCdGEsvqrZfNVbGcn9ns2RNA"
            rel="noreferrer"
            aria-label="youtube"
          >
            <YouTubeIcon className="footer-icons" fontSize="inherit" />
          </IconButton>
        </div>
        <div className="official-docs">
          <Link to="/documents">Official Documents</Link>
        </div>
        <div className="terms">
          <Link to="/terms">Terms and Conditions</Link>
        </div>
        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} EESTEC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);
