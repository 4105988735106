import { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import Toast from 'components/Toast/Toast';
import { ModalProvider } from 'context/ModalContext';
import { PartnersProvider } from 'context/PartnersContext';
import Header from 'layouts/MainLayout/components/Header/Header';
import Footer from 'layouts/MainLayout/components/Footer/Footer';

/**
 Layout is the basic layout component which wraps the route outlet with the header and the footer.
 */
function MainLayout() {
  const { pathname } = useLocation();
  // Scroll to top of the website whenever users change pages
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return (
    <ModalProvider>
      <PartnersProvider>
        <Header />
        <Outlet />
        <Footer />
        <Modal />
        <Toast />
      </PartnersProvider>
    </ModalProvider>
  );
}

export default MainLayout;
