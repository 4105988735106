import { useContext, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from 'context/AuthContext';
import ToastContext from 'context/ToastContext';

/**
 __Private__ is a routing component wrapping actual contents of the page that a given route
 is pointing to. It handles the authorization and making sure that pages available only
 for registered users with certain privileges are hidden to others.
*/
function Private({ Component, allowUnverified }) {
  const { loggedIn, userData } = useContext(AuthContext);
  const { setToastContent } = useContext(ToastContext);
  const { pathname, search } = useLocation();

  // Check the user's privileges and redirect if the user is not authorized
  return useMemo(() => {
    // If someone is logged out and wants to access a Private route, redirect to the login page
    let redirectPath = '/login';

    if (loggedIn) {
      const isUnverified = !(
        userData?.email_verified_at && userData?.branch_verified_at
      );
      if (allowUnverified || !isUnverified) {
        // Render the actual view if access verification returns true
        return <Component />;
      }

      // If someone is logged in but still should not see the page due to not enough privileges
      // redirect to homepage
      setToastContent(
        'The page you requested cannot be accessed as your account is not yet verified.',
        'warning',
      );
      redirectPath = '/';
    }
    return (
      <Navigate
        to={redirectPath}
        state={{ from: `${pathname}${search}` }}
        replace
      />
    );
  }, [
    allowUnverified,
    loggedIn,
    pathname,
    setToastContent,
    search,
    userData?.email_verified_at,
    userData?.branch_verified_at,
  ]);
}

Private.propTypes = {
  /**
   An element type being the view to be presented on the given route.
  */
  Component: PropTypes.elementType.isRequired,
  /**
   A boolean value indicating whether a page should be accessible by a registered
   user who is not verified.
  */
  allowUnverified: PropTypes.bool,
};

Private.defaultProps = {
  allowUnverified: false,
};

export default Private;
