import { createContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext(null);

/**
 __ModalProvider__ is a React Context provider used for displaying information in modals.
 Since toasts can be shown on different pages on the website,
 a global state had to be used for it.

 Another approach to implement modals could be done by using React Portals.
*/
export function ModalProvider({ children }) {
  const [modalContent, setModalContent] = useState(null);

  const modalValue = useMemo(
    () => ({ modalContent, setModalContent }),
    [modalContent, setModalContent],
  );

  useEffect(() => {
    if (modalContent) {
      document.body.classList.add('locked-scroll');
    } else {
      document.body.classList.remove('locked-scroll');
    }
  }, [modalContent]);

  return (
    <ModalContext.Provider value={modalValue}>{children}</ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalContext;
