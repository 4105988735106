import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import reportWebVitals from 'reportWebVitals';
import Routing from 'routes/RouteProvider';
import { AuthProvider } from 'context/AuthContext';
import { ToastProvider } from 'context/ToastContext';

import 'utils/fonts';
import 'index.css';

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <ToastProvider>
          <Routing />
        </ToastProvider>
      </StyledEngineProvider>
    </AuthProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
