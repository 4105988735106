import { lazy } from 'react';

const Home = lazy(() => import('views/public/Home/Home'));
const Events = lazy(() => import('views/public/Events/Events'));
const Branches = lazy(() => import('views/public/Branches/Branches'));
const JoinUs = lazy(() => import('views/public/JoinUs/JoinUs'));
const AboutUs = lazy(() => import('views/public/AboutUs/AboutUs'));
const Partners = lazy(() => import('views/public/Partners/Partners'));
const Documents = lazy(() => import('views/public/Documents/Documents'));
const Terms = lazy(
  () => import('views/public/TermsAndConditions/TermsAndConditions'),
);
const Contact = lazy(() => import('views/public/Contact/Contact'));
const Branch = lazy(() => import('views/public/Branch/Branch'));
const Event = lazy(() => import('views/public/Event/Event'));
const ProfileRouting = lazy(
  () =>
    import('views/internal/Profile/components/ProfileRouting/ProfileRouting'),
);

// commonly accessible routes of the main layout (header+footer)
export default [
  {
    // a path of the desired view
    path: '/',
    // the component to be rendered at the given path
    Element: Home,
    // indicates whether the route should be exact or just the beginning should match
    exact: true,
  },
  {
    path: '/events',
    Element: Events,
    exact: true,
  },
  {
    path: '/cities',
    Element: Branches,
    exact: true,
  },
  {
    path: '/join-us',
    Element: JoinUs,
    exact: true,
  },
  {
    path: '/about',
    Element: AboutUs,
    exact: true,
  },
  {
    path: '/partners',
    Element: Partners,
    exact: true,
  },
  {
    path: '/documents',
    Element: Documents,
    exact: true,
  },
  {
    path: '/terms',
    Element: Terms,
    exact: true,
  },
  {
    path: '/contact',
    Element: Contact,
    exact: true,
  },
  {
    path: '/cities/:branchSlug',
    Element: Branch,
  },
  {
    path: '/events/:eventSlug',
    Element: Event,
  },
  {
    // this is a private path but the logic behind resolving the route is elsewhere
    // so consider it common
    path: '/users/:userSlug',
    Element: ProfileRouting,
  },
];
