import { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 __PublicOnly__ is a routing component wrapping actual contents of the page that a given route
 is pointing to. It handles the authorization and making sure that pages available only
 for not registered people (such as the `login`/`register`/`forgot-password` pages) are not
 displayable for those who are logged in.
*/
function PublicOnly({ Component }) {
  const { state } = useLocation();
  // Sometimes even with a successful login we should still be able to see a page
  // that should be hidden for registered people (such as EmailInfo).
  // The shouldRedirect property gives us the proper information
  const { loggedIn, shouldRedirect } = useContext(AuthContext);

  return loggedIn && shouldRedirect ? (
    <Navigate to={state?.from || '/'} replace />
  ) : (
    <Component />
  );
}

PublicOnly.propTypes = {
  /**
   An element type being the view to be presented on the given route.
  */
  Component: PropTypes.elementType.isRequired,
};

export default PublicOnly;
