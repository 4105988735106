import { useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Outlet } from 'react-router-dom';
import ToastContext from 'context/ToastContext';

import './LogoLayout.css';
import logoEESTEC from 'assets/img/eestec-logo_white.svg';

/**
 __LogoLayout__ is a layout which has EESTEC logo on top - used mostly for auth views
 */
function LogoLayout({ className }) {
  const { setToastContent } = useContext(ToastContext);

  // prevent logout toast to show after login if login was done right after logout and the toast
  // didn't manage to disappear on time
  useLayoutEffect(() => {
    setToastContent(null);
  }, [setToastContent]);

  return (
    <div className={`logo-layout${className ? ` ${className}` : ''}`}>
      <Link to="/" className="logo-layout-logo">
        <img alt="EESTEC logo white" src={logoEESTEC} />
      </Link>
      <Outlet />
    </div>
  );
}

LogoLayout.propTypes = {
  className: PropTypes.string,
};

LogoLayout.defaultProps = {
  className: null,
};

export default LogoLayout;
