import axios from 'axios';
import { authRequest, request } from 'api/config/request';
import { BASE_URL } from 'api/config/defaults';

export default class AuthService {
  static initCsrf() {
    return request(axios, {
      url: `${BASE_URL}/sanctum/csrf-cookie`,
      method: 'GET',
    });
  }

  static async register(data) {
    await this.initCsrf();
    return authRequest({
      url: '/register',
      method: 'POST',
      data,
    });
  }

  static async login(data) {
    await this.initCsrf();
    return authRequest({
      url: '/login',
      method: 'POST',
      data,
    });
  }

  static async logout() {
    return authRequest({
      url: '/logout',
      method: 'POST',
    });
  }

  static forgotPassword(data) {
    return authRequest({
      url: '/forgot-password',
      method: 'POST',
      data,
    });
  }

  static resetPassword(data, token) {
    return authRequest({
      url: '/reset-password',
      method: 'POST',
      params: {
        token,
      },
      data,
    });
  }

  static resendEmailVerification() {
    return authRequest({
      url: '/email/verification-notification',
      method: 'POST',
    });
  }

  static verifyEmail(userId, token, params) {
    return authRequest({
      url: `email/verify/${userId}/${token}`,
      method: 'GET',
      params,
    });
  }
}
