/**
 Menu contains all the navigation links used in the footer and the header.
*/

const menu = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About us',
    link: '/about',
  },
  {
    name: 'Cities',
    link: '/cities',
  },
  {
    name: 'Events',
    link: '/events',
  },
  {
    name: 'Partners',
    link: '/partners',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
];

export default menu;
