import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const PartnersContext = createContext([]);

/**
 __PartnersProvider__ is a React Context provider used for storing and displaying
 partners data fetched from the back end. A context is used for this purpose since partners
 data is shown on multiple pages of the website. Storing partners like this - as a global state -
 makes it possible to reuse this data without re-fetching it every time.
*/
export function PartnersProvider({ children }) {
  const [partners, setPartners] = useState([]);

  const partnersValue = useMemo(
    () => ({ partners, setPartners }),
    [partners, setPartners],
  );

  return (
    <PartnersContext.Provider value={partnersValue}>
      {children}
    </PartnersContext.Provider>
  );
}

PartnersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PartnersContext;
