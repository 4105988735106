/*
 This file contains all the utility functions that could not be categorized
 into other files within the directory.
*/

export const pluralize = (word, count = 2) => (count === 1 ? word : `${word}s`);

export const capitalizeFirstLetter = (phrase) =>
  phrase.charAt(0).toUpperCase() + phrase.slice(1);

// Create an uppercase acronym out of a string containing words separated by spaces
export const acronym = (phrase) => {
  // If the input string is empty or null/undefined, return an empty string
  if (!phrase) {
    return '';
  }

  const phraseArray = phrase.split(' ');
  if (phraseArray.length > 1) {
    return phraseArray
      .map((word) => word.slice(0, 1))
      .join('')
      .toUpperCase();
  }

  // If there was only one word within the input phrase, return the whole phrase capitalized
  return capitalizeFirstLetter(phrase);
};

// Replace the literal %nbsp; text found in the old database with an actual space
export const removeNbsp = (phrase) => phrase?.replaceAll('&nbsp;', ' ');

// Take an object and for all its keys set the same value provided as the second argument
export const setAllKeysToValue = (object, value) => {
  const returnObject = {};
  Object.keys(object).forEach((key) => {
    returnObject[key] = value;
  });
  return returnObject;
};

// Format the input number of bytes into a nice display with appropriate prefixes
export const niceBytes = (x) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0;
  let n = parseInt(x, 10) || 0;

  while (n >= 1024) {
    l += 1;
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

// Try to find an env variable specified by the key and fallback to defaultValue if not found
/*eslint-env node*/
export const envOrDefault = (key, defaultValue) =>
  process.env[key] || defaultValue;

// Split a string containing newlines into renderable paragraphs, separated by those newlines
export const createParagraphs = (
  content,
  id,
  className = null,
  separator = '\r\n',
) =>
  !content ? (
    ''
  ) : (
    <>
      {content.split(separator).map((paragraph, index) =>
        !paragraph ? null : (
          // eslint-disable-next-line react/no-array-index-key
          <p className={className} key={`${id}-${index}`}>
            {paragraph}
          </p>
        ),
      )}
    </>
  );

export const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const startsWithAny = (string, array) => {
  for (const arrayString of array) {
    if (string.startsWith(arrayString)) {
      return true;
    }
  }
  return false;
};
