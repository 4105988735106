/*
 This file contains helper functions for the API services
 defined in `/api` project directory.
*/

// eslint-disable-next-line import/prefer-default-export
export function getFormData(object) {
  const formData = new FormData();
  // Convert object for formData
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}
