import { useLayoutEffect, useTransition, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import topbar from 'topbar';

// Configure the topbar for routes changes
topbar.config({
  barThickness: 5,
  barColors: {
    0: '#e52a30',
    '1.0': '#ae0308',
  },
  shadowBlur: 5,
  shadowColor: 'rgba(0, 0, 0, .5)',
});

function LazyRouter({ window, children, basename }) {
  const historyRef = useRef();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window, v5Compat: true });
  }

  const history = historyRef.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  const [isPending, startTransition] = useTransition();

  useLayoutEffect(
    () =>
      history.listen((update) => {
        topbar.show();
        startTransition(() => {
          setState(update);
        });
      }),
    [history],
  );

  useLayoutEffect(() => {
    if (!isPending) {
      topbar.hide();
    }
  }, [isPending]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

LazyRouter.propTypes = {
  children: PropTypes.node,
  basename: PropTypes.string,
  window: PropTypes.instanceOf(window.constructor),
};

LazyRouter.defaultProps = {
  children: null,
  basename: undefined,
  window: undefined,
};

export default LazyRouter;
