import { Suspense } from 'react';
import { Routes } from 'react-router-dom';
import routing from 'routes/routes';
import LazyRouter from 'routes/RouteProvider/LazyRouter';
import {
  renderRedirections,
  renderRouteCategory,
} from 'routes/RouteProvider/utils/routesRendering';

function Routing() {
  return (
    <LazyRouter>
      <Suspense>
        <Routes>
          {renderRouteCategory(routing.routes.mainLayout)}
          {renderRouteCategory(routing.routes.logoLayout)}
          {renderRedirections(routing.redirections)}
        </Routes>
      </Suspense>
    </LazyRouter>
  );
}

export default Routing;
