import PropTypes from 'prop-types';
import { Link as RRDLink, useMatch, useResolvedPath } from 'react-router-dom';

import 'components/NavLink/NavLink.css';

/**
 __NavLink__ is a main link component of the layout's navigation. It is used by
 both the `Header` and the `Footer`. The `NavLink` has an "active" styling
 when the current path matches the destination path of this component.
 */
function NavLink({ children, to, className, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const classes = `${className} nav-link-component ${match ? 'active' : ''}`;
  return (
    <RRDLink to={to} {...props} className={classes}>
      <span className="nav-text-span">{children}</span>
    </RRDLink>
  );
}

NavLink.propTypes = {
  /**
   The link's text content.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   A destination URL (relative or absolute).
  */
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NavLink.defaultProps = {
  children: null,
  className: '',
};

export default NavLink;
