import { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ToastContext = createContext(null);

/**
 __ToastProvider__ is a React Context provider used for displaying information in toasts.
 Since toasts can be shown on different pages on the website and possibly can still be
 visible while users change the pages, a global state had to be used for it.
*/
export function ToastProvider({ children }) {
  // properties to be used by the Toast component
  const [toast, setToast] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
    content: '',
    autoHideDuration: 6000,
    type: 'info',
  });

  const setToastContent = useCallback(
    (content, type = null, autoHideDuration = 6000) => {
      setToast((prevState) => ({
        ...prevState,
        content,
        open: true,
        autoHideDuration,
        type: type || 'info',
      }));
    },
    [setToast],
  );

  const toastValue = useMemo(
    () => ({
      // Toast content
      toast,
      // direct setting function of Toast
      setToast,
      // a function that should be used by external components to set Toast content
      setToastContent,
    }),
    [toast, setToast, setToastContent],
  );

  return (
    <ToastContext.Provider value={toastValue}>{children}</ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastContext;
