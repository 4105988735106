/*
 This file contains the definition of the logger - main LOGGER of the project
 that should be always used instead of regular console.log(etc).

 Logger is just an implementation of console, with that difference that
 the logging is NOT happening if we are in production mode.
 Therefore we can log out different information using logger and still make sure that
 these messages won't be visible to the end user.

 Used mostly for logging errors.

 Logger is able to call exactly the same methods as console, as it implements it.
 So `logger.log()`, `logger.error()`, `logger.warn()` etc. are all valid.
*/

/*eslint-env node*/
export default process.env.NODE_ENV === 'production'
  ? Object.fromEntries(
      Object.keys(console).map((key) => [key, () => undefined]),
    )
  : console;
