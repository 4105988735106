/*
 This file contains functions used to modify the local storage of browsers.
 These functions are used by the context functions dealing with auth data
 and most probably nothing more will have to be stored in the local storage any time soon.
*/

import logger from 'utils/logger';

export function saveToLocalStorage(key, state) {
  try {
    if (!state) {
      return;
    }
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    logger.warn(e);
  }
}

export function loadFromLocalStorage(key) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (e) {
    logger.warn(e);
    return undefined;
  }
}
