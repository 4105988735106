import PropTypes from 'prop-types';

import NavLink from 'components/NavLink/NavLink';

import './FooterNavLink.css';

/**
 __FooterNavLink__ is another footer's version of Link.
 */
function FooterNavLink({ children, to, className, ...props }) {
  const classes = `${className} footer-nav-link`;
  return (
    <NavLink className={classes} to={to} {...props}>
      {children}
    </NavLink>
  );
}

FooterNavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FooterNavLink.defaultProps = {
  children: null,
  className: '',
};

export default FooterNavLink;
