/* eslint-disable camelcase,prefer-const */

import { apiRequest } from 'api/config/request';
import { getFormData } from 'utils/api';

export default class UserService {
  static getUserData(abortSignal = null) {
    return apiRequest({
      url: '/user',
      method: 'GET',
      signal: abortSignal,
    });
  }

  static getUserProfile(slug, abortSignal) {
    return apiRequest({
      url: `/users/${slug}`,
      method: 'GET',
      signal: abortSignal,
    });
  }

  static getUserAttendedEvents(slug, abortSignal) {
    return apiRequest({
      url: `/users/${slug}/events-attended`,
      method: 'GET',
      signal: abortSignal,
    });
  }

  static getUserOrganizedEvents(slug, abortSignal) {
    return apiRequest({
      url: `/users/${slug}/events-organized`,
      method: 'GET',
      signal: abortSignal,
    });
  }

  static editUserData(
    slug,
    data,
    withBannerPicture = false,
    withSquarePicture = false,
  ) {
    let { profile_photo_path, banner_path, ...requestData } = data;
    if (withBannerPicture) {
      requestData = {
        ...requestData,
        banner_path,
      };
    } else if (withSquarePicture) {
      requestData = {
        ...requestData,
        profile_photo_path,
      };
    }
    return apiRequest({
      url: `/users/${slug}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: getFormData({
        ...requestData,
        _method: 'PUT',
      }),
    });
  }
}
