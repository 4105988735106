import { Navigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 __Redirect__ is a component that handles redirections for routes while keeping
 the params declared in the RouteProvider and passing them to the page we want.
*/
function Redirect({ to, replace, paramNames }) {
  const params = useParams();
  const location = useLocation();

  let url = to;
  // Add the URL params from paramNames
  paramNames.forEach((paramName) => {
    url += `/${params[paramName]}`;
  });
  // Add also search params if any exist
  url += location.search;

  return <Navigate to={url} replace={replace} state={location.state} />;
}

Redirect.propTypes = {
  /**
   A boolean value indicating whether the new route should replace the current one
   in the browser history.
  */
  replace: PropTypes.bool,
  /**
   A string representing the route to redirect to.
  */
  to: PropTypes.string.isRequired,
  /**
   An array of string values being the URL params names to be passed to the page.
  */
  paramNames: PropTypes.arrayOf(PropTypes.string),
};

Redirect.defaultProps = {
  replace: false,
  paramNames: [],
};

export default Redirect;
