import { lazy } from 'react';

const SignIn = lazy(() => import('views/auth/SignIn/SignIn'));
const SignUp = lazy(() => import('views/auth/SignUp/SignUp'));
const PasswordReset = lazy(
  () => import('views/auth/PasswordReset/PasswordReset'),
);

// public-only routes of the logo layout
export default [
  {
    // a path of the desired view
    path: '/login',
    // indicates whether the route should be exact or just the beginning should match
    exact: true,
    // the component to be rendered at the given path
    Element: SignIn,
  },
  {
    path: '/register',
    exact: true,
    Element: SignUp,
  },
  {
    path: '/reset-password',
    exact: true,
    Element: PasswordReset,
  },
];
