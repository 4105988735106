/*
 This file contains all the utility functions solely related to the rendering of routes,
 used only by the RouteProvider
*/

import { Route } from 'react-router-dom';
import Private from 'routes/Private';
import PublicOnly from 'routes/PublicOnly';
import Redirect from 'routes/Redirect';

// renderCommonRoutes renders views accessible for everyone
const renderCommonRoutes = (routes) =>
  routes.map(({ path, exact, Element }) => (
    <Route key={path} path={path} exact={exact} element={<Element />} />
  ));

// renderPublicRoutes renders views accessible only for non-logged in people
const renderPublicRoutes = (routes) =>
  routes.map(({ path, exact, Element }) => (
    <Route
      key={path}
      path={path}
      exact={exact}
      element={<PublicOnly Component={Element} />}
    />
  ));

// renderPrivateRoutes renders views accessible only for logged in people
const renderPrivateRoutes = (routes) =>
  routes.map(({ path, exact, Element, allowUnverified }) => (
    <Route
      key={path}
      path={path}
      exact={exact}
      element={
        <Private Component={Element} allowUnverified={allowUnverified} />
      }
    />
  ));

// renderRouteCategory renders a single category specified in the routes subdirectory
// - a category can be routes with header+footer layout or a clear view render
export const renderRouteCategory = ({ routes, root = null }) => {
  const content = (
    <>
      {renderCommonRoutes(routes.common)}
      {renderPrivateRoutes(routes.private)}
      {renderPublicRoutes(routes.public)}
    </>
  );

  // If root element is specified, that means a layout has to be used, so wrap the content
  // in the layout route
  if (root) {
    const { Element, path } = root;
    return (
      <Route path={path} element={<Element />} key={`route-root-${path}`}>
        {content}
      </Route>
    );
  }

  return content;
};

// renderRedirections takes care of rendering the redirections section
export const renderRedirections = (redirections) =>
  redirections.map(({ source, destination, replace, exact, paramNames }) => (
    <Route
      key={source}
      path={source}
      exact={exact}
      element={
        <Redirect to={destination} replace={replace} paramNames={paramNames} />
      }
    />
  ));
