import { useState, useCallback, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { startsWithAny } from 'utils/misc';
import { privateRoutes } from 'routes/routes';

import AuthService from 'api/service/auth';
import ToastContext from 'context/ToastContext';
import logger from 'utils/logger';

import './HeaderUserBadge.css';

export default function HeaderUserBadge({
  loggedIn,
  logout,
  firstName,
  setHamburgerStatus,
}) {
  const [isHeaderButtonLoading, setIsHeaderButtonLoading] = useState(false);
  const [headerButtonsExpanded, setHeaderButtonsExpanded] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setToastContent } = useContext(ToastContext);

  const wrapHeadersButton = useCallback(() => {
    if (headerButtonsExpanded) {
      setHeaderButtonsExpanded(false);
    }
  }, [headerButtonsExpanded, setHeaderButtonsExpanded]);

  // show a visual indicator of a logout process, display a toast and modify stored user information
  const handleLogout = () => {
    setIsHeaderButtonLoading(true);
    AuthService.logout()
      .then(() => {
        if (startsWithAny(pathname, privateRoutes)) {
          navigate('/');
        }
        logout();
        setToastContent('You have been logged out successfully!', 'success');
      })
      .catch((err) => {
        logger.log(err);
      })
      .finally(() => setIsHeaderButtonLoading(false));
  };

  // onExpandedMenuClick handles the logic after a click on an element of an expanded user button
  // It turns off the hamburger, wrap the user button and executes a callback
  const onExpandedMenuClick = (callback) => {
    wrapHeadersButton();
    setHamburgerStatus(false);
    callback();
  };

  const goToLogin = () => {
    navigate('/login', { state: { from: pathname } });
  };

  const mainButtonProps = loggedIn
    ? {
        // if logged in, the user button expands and gives more options
        onClick: () => setHeaderButtonsExpanded((prevState) => !prevState),
        text: firstName ? firstName.split(' ')[0] : null,
        icon: ExpandMoreIcon,
      }
    : {
        // if logged out, the user button redirects to the login page
        onClick: goToLogin,
        text: 'Sign in',
        icon: PersonIcon,
      };

  // Doing this, so we can use it as an element later
  const MainButtonIcon = mainButtonProps.icon;

  return (
    <ClickAwayListener onClickAway={wrapHeadersButton}>
      <div
        className={`header-profile-nav${
          headerButtonsExpanded ? ' expanded' : ''
        }`}
      >
        <ButtonBase
          onClick={mainButtonProps.onClick}
          className="header-profile-btn header-profile-main-btn"
        >
          <span
            className={`header-profile-btn-text${
              mainButtonProps.text.length > 8 ? ' small-text' : ''
            }`}
          >
            {mainButtonProps.text}
          </span>
          {isHeaderButtonLoading ? (
            <div className="header-login-btn-loading-icon-wrapper">
              <CircularProgress
                className="header-login-btn-loading-icon"
                size={21}
              />
            </div>
          ) : (
            <MainButtonIcon className="header-profile-btn-icon" />
          )}
        </ButtonBase>
        {loggedIn && (
          <ul className="header-profile-expanded-buttons">
            <li>
              <ButtonBase
                onClick={() => onExpandedMenuClick(() => () => {})}
                className="header-profile-btn"
                component={Link}
                to="/my-profile"
              >
                <span className="header-profile-btn-text">My profile</span>
                <PersonIcon className="header-profile-btn-icon" />
              </ButtonBase>
            </li>
            <li>
              <ButtonBase
                onClick={() => onExpandedMenuClick(handleLogout)}
                className="header-profile-btn"
              >
                <span className="header-profile-btn-text">Log out</span>
                <LogoutIcon className="header-profile-btn-icon" />
              </ButtonBase>
            </li>
          </ul>
        )}
      </div>
    </ClickAwayListener>
  );
}

HeaderUserBadge.propTypes = {
  loggedIn: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  setHamburgerStatus: PropTypes.func.isRequired,
};

HeaderUserBadge.defaultProps = {
  firstName: '',
  loggedIn: false,
};
