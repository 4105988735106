// definitions of redirections
export default [
  {
    // a source path of the redirection
    source: '/branches/:branchSlug',
    // a destination path of the redirection (without parameters)
    destination: '/cities',
    // indicates whether a browser history element should be replaced or just pushed to the stack
    replace: true,
    // parameters names (in the order of occurrence) that should be put into the redirection
    paramNames: ['branchSlug'],
  },
  {
    source: '/branches',
    destination: '/cities',
    replace: true,
    // indicates whether the route should be exact or just the beginning should match
    exact: true,
  },
  {
    // redirect all the unresolved paths to 404
    source: '*',
    destination: '/404',
    replace: true,
  },
];
