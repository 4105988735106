import { lazy } from 'react';

const NotFound = lazy(() => import('views/public/NotFound/NotFound'));
const EmailInfo = lazy(() => import('views/auth/EmailInfo/EmailInfo'));

// commonly accessible routes of the logo layout
export default [
  {
    // a path of the desired view
    path: ':referer/email',
    // the component to be rendered at the given path
    Element: EmailInfo,
  },
  {
    path: '/404',
    // indicates whether the route should be exact or just the beginning should match
    exact: true,
    Element: NotFound,
  },
];
