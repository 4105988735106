import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import menu from 'layouts/MainLayout/utils/menu';

import UserService from 'api/service/user';
import ModalContext from 'context/ModalContext';
import AuthContext from 'context/AuthContext';
import HeaderNavLink from 'layouts/MainLayout/components/Header/components/HeaderNavLink/HeaderNavLink';
import HeaderUserBadge from 'layouts/MainLayout/components/Header/components/HeaderUserBadge/HeaderUserBadge';
// import HeaderSearchButton from 'layouts/Header/components/HeaderSearchButton/HeaderSearchButton';

import './Header.css';
import logo from 'assets/img/eestec-logo_white.svg';

/**
 __Header__ is the component displayed on the top of the page of the default layout.
 It displays the navigation links to the main pages of the website and handles some authentication
 actions on the website: it contains code for the logout process and checking of auth status.
 */
export default function Header() {
  const { modalContent } = useContext(ModalContext);
  const { loggedIn, logout, updateUserData, userData } =
    useContext(AuthContext);

  const [hamburgerStatus, setHamburgerStatus] = useState(false);

  // use the synchronous layout effect to check if the user is still logged in
  useLayoutEffect(() => {
    if (loggedIn) {
      UserService.getUserData()
        .then((userDataResponse) => {
          updateUserData(userDataResponse);
        })
        .catch(() => {
          logout();
        });
    }
  }, [loggedIn, logout, updateUserData]);

  // add scroll locking of the rest of the page whenever the hamburger menu is expanded
  useEffect(() => {
    if (hamburgerStatus) {
      document.body.classList.add('locked-scroll');
    } else {
      document.body.classList.remove('locked-scroll');
    }

    return () => document.body.classList.remove('locked-scroll');
  }, [hamburgerStatus]);

  // navLink Close Hamburger Menu
  const onClickHandler = () => {
    setHamburgerStatus(false);
  };

  let classes = 'header';
  classes += modalContent ? ' blurred' : '';

  const hamburgerIconTitle = 'Open a mobile menu';

  return (
    <header className={classes}>
      <div className="header-hamburger-logo">
        <button
          type="button"
          className={`header-hamburger ${hamburgerStatus ? 'active' : ''}`}
          onClick={() => setHamburgerStatus(!hamburgerStatus)}
          title={hamburgerIconTitle}
          aria-label={hamburgerIconTitle}
          aria-expanded={hamburgerStatus}
          aria-controls="menu"
        >
          <span className="header-hamburger-bar" />
          <span className="header-hamburger-bar" />
          <span className="header-hamburger-bar" />
        </button>
        <Link className="header-logo-btn" to="/" onClick={onClickHandler}>
          <img className="header-logo" alt="EESTEC logo" src={logo} />
        </Link>
      </div>

      <div className={`header-items ${hamburgerStatus ? 'active' : ''}`}>
        <nav>
          <ul id="menu" className="header-navbar-list">
            {menu.map(({ name, link }) => (
              <li key={`header-link-${name}`}>
                <HeaderNavLink to={link} onClickHandler={onClickHandler}>
                  {name}
                </HeaderNavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="header-right-nav">
          {/*
              <HeaderSearchButton />
              disabled for the time being - until we find an actual functionality of this
            */}
          <HeaderUserBadge
            loggedIn={loggedIn}
            logout={logout}
            firstName={userData ? userData.first_name : null}
            setHamburgerStatus={setHamburgerStatus}
          />
        </div>
      </div>
    </header>
  );
}
