import commonRoutes from 'routes/routes/logo/common';
import privateRoutes from 'routes/routes/logo/private';
import publicRoutes from 'routes/routes/logo/public';
import LogoLayout from 'layouts/LogoLayout/LogoLayout';

// export all the routes of the logo layout
export default {
  root: {
    path: '/',
    Element: LogoLayout,
  },
  routes: {
    common: commonRoutes,
    private: privateRoutes,
    public: publicRoutes,
  },
};
