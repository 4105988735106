import { useCallback, useContext, useEffect } from 'react';

import ModalContext from 'context/ModalContext';

import 'components/Modal/Modal.css';

/**
 __Modal__ is an element with a fixed position, which stands out and lays an overlay
 in order to dim other elements when it is displayed.

 __Context component__: `Modal` does not have its own props, all its contents
 come from the `ModalContext` properties. The only property `modalContent`
 can be any kind of JSX node that should be presented as a modal.
 */
function Modal() {
  const { modalContent, setModalContent } = useContext(ModalContext);
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setModalContent(null);
      }
    },
    [setModalContent],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const disableModal = () => setModalContent(null);
  const stopPropagation = (e) => e.stopPropagation();

  if (!modalContent) {
    return null;
  }

  return (
    <div
      className="modal-overlay"
      role="presentation"
      onClick={disableModal}
      onKeyDown={disableModal}
    >
      <div
        className="modal"
        role="presentation"
        onClick={stopPropagation}
        onKeyDown={stopPropagation}
      >
        {modalContent}
      </div>
    </div>
  );
}

export default Modal;
