import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { loadFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';
import PropTypes from 'prop-types';

const AuthContext = createContext({
  loggedIn: false,
  userData: null,
});

/**
 __AuthProvider__ is a React Context provider used for storing auth data of the user.
 This global state stores the information on the user data, current login status,
 and provides function to manipulate the user data: to logout, to update user data
 and manipulate auth redirections.

 This global state provider works with a browser's local storage,
 it tries loading the auth data from the local storage on mount and saves the new data
 to the local storage on each update.
*/
export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(loadFromLocalStorage('authData'));
  const [shouldRedirect, setShouldRedirect] = useState(true);

  const enableAuthRedirection = useCallback(
    () => setShouldRedirect(true),
    [setShouldRedirect],
  );
  const logout = useCallback(() => {
    setAuth({
      loggedIn: false,
      userData: null,
    });
    setShouldRedirect(true);
  }, [setAuth, setShouldRedirect]);
  const updateUserData = useCallback(
    ({ data }, newShouldRedirect = true, addData = false) => {
      setAuth((prevState) => ({
        loggedIn: true,
        userData: addData ? { ...prevState.userData, ...data } : data,
      }));
      setShouldRedirect(newShouldRedirect);
    },
    [setAuth, setShouldRedirect],
  );

  const authValue = useMemo(
    () => ({
      ...auth,
      shouldRedirect,
      enableAuthRedirection,
      logout,
      updateUserData,
    }),
    [enableAuthRedirection, shouldRedirect, auth, logout, updateUserData],
  );

  // On every single change of user auth data, update the local storage
  useEffect(() => {
    saveToLocalStorage('authData', auth);
  }, [auth]);

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
